import { StatusEnum } from "../api/status";

export const backgroundClassForStatus = (status: StatusEnum): string => {
  switch (status) {
    case 0:
      return "!bg-red-500 text-white font-sembold";
    case 1:
      return "!bg-yellow-300";
    case 2:
      return "!bg-green-700";
    case 3:
      return "!bg-blue-700";
    default:
      return "";
  }
}
