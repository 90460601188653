
export type StatusEnum = 0 | 1 | 2 | 3;


export interface IWrhsEntry {
  bin_id: string;
  item_seed: string;
  qty: number;
  qty_toh: number;
  qty_tpp: number;
  type_id: string;
  wrhs_id: string;
}

export interface ISummaryLine {
  item_id: string;
  qty_torder: number;
}

export interface IStatusLine {
  from_bin: string;
  inv_entry: IWrhsEntry[];
  item_id: string;
  item_seed: string;
  kanban_id: string;
  key_qty_torder: string;
  mesg: string;
  move_qty: number;
  ord_priority: number;
  pos_size: string;
  prod_no: string;
  qty_in_pack: number;
  qty_in_pick: number;
  qty_in_pick_bin: number;
  qty_of_order: number;
  qty_torder: number;
  repl_qty: number;
  status: StatusEnum;
  to_bin: string;
  type_id: string;
  wrhs_id: string;
}

export interface IPickSummary {
  name: string;
  pick_count: number;
}

export interface IStatusApiResult {
  pickline_status_lines: IStatusLine[];
  summary_results: ISummaryLine[];
  picks_to_print: IPickSummary[];
}

export interface IStatusFetchResult {
  status?: IStatusApiResult;
  error?: string;
}

export const loadPickLineStatus = async (): Promise<IStatusFetchResult> => {
  try {
    const result = await fetch("https://privapi.distribution-online.com/pickline/api/v2/status.json");

    if (result.status >= 500) {
      return {
        error: "Encountered an internal server error while refreshing the latest status"
      }
    } else if (result.status === 0) {
      return {
        error: "Encountered a network error while refreshing the latest status, please ensure you are on-site or have the VPN enabled"
      }
    }
  
    return {
      status: await result.json() as IStatusApiResult
    }
  }
  catch (err) {
    return {
      error: "Encountered a network error while refreshing the latest status, please ensure you are on-site or have the VPN enabled"
    }
  }
}
