import * as React from "react";
import { StatusEnum } from "../../api/status";
import { backgroundClassForStatus } from "../../util/statusBackgroundClasses";

export interface IColorCountPanelProps {
  count: number;
  code: StatusEnum;
}

export const ColorCountPanel = (props: IColorCountPanelProps) => {
  return (
    <div className="rounded-md border-black border-solid border">
      <div className={`w-full h-5 rounded-t-md ${backgroundClassForStatus(props.code)}`}></div>
      <p className="px-2 py-1">{props.count}</p>
    </div>
  )
}
