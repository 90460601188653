import * as React from "react";

export interface IStatusEntry {
  name: string;
  count: number;
}

export interface ISummaryStatusListProps {
  summaryResults: IStatusEntry[];
  title: string;
}

export const SummaryStatusList = (props: ISummaryStatusListProps) => {
  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">{props.title}</h3>
      <ul className="grid grid-cols-2 gap-x-2">
        {props.summaryResults.map(result => (
          <li className="flex flex-row border border-solid border-black px-2">
            <span className="inline-block mr-auto">{result.name}</span>
            <span className={(result.count > 0) ? "font-semibold text-lg" : ""}>{result.count}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
