import * as React from "react";
import { PickLineStatusContext } from "../context/PickLineStatusContext";
import loader from "../img/loading.gif";


export const Loader = () => {
  const ctx = React.useContext(PickLineStatusContext);

  return (
    <>
      {!ctx.status && (
        <div className="w-full m-12">
          <img alt={"Loading..."} src={loader} width={150} />
        </div>
      )}
    </>
  )
}