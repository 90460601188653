import * as React from "react";
import { IStatusApiResult, StatusEnum } from "../../api/status";
import { ColorCountPanel } from "./ColorCountPanel";
import { SummaryStatusList } from "./SummaryStatusList";

export interface IStatusSummaryProps {
  status: IStatusApiResult
}

const STATUS_CODES: StatusEnum[] = [0, 1, 2, 3]

export const StatusSummary = (props: IStatusSummaryProps) => {
  const countsByStatus = React.useMemo<Map<StatusEnum, number>>(() => {
    const map = new Map<StatusEnum, number>();
    STATUS_CODES.forEach(s => map.set(s, 0));

    props.status.pickline_status_lines.forEach(line => {
      const count = map.get(line.status)
      map.set(line.status, (count || 0) + 1)
    });

    return map;
  }, [props.status]);

  const picklineTotalResults = React.useMemo(() => props.status.summary_results.map((res) => ({
    name: res.item_id,
    count: res.qty_torder,
  })), [props.status])

  const picksToPrintResults = React.useMemo(() => props.status.picks_to_print.map(summary => ({
    name: summary.name,
    count: summary.pick_count,
  })), [props.status])

  return (
    <div className="flex flex-row items-start">
      <div className="w-1/3 px-8">
        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
          {STATUS_CODES.map(code => (
            <ColorCountPanel key={code} code={code} count={countsByStatus.get(code) || 0} />
          ))}
        </div>
      </div>
      <div className="w-1/3 px-12">
        <SummaryStatusList title="Blocked Orders" summaryResults={picklineTotalResults} />
      </div>
      <div className="w-1/3 px-12">
        <SummaryStatusList title="Picks Ready to Print" summaryResults={picksToPrintResults}></SummaryStatusList>
      </div>
    </div>
  )
}
