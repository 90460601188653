import * as React from "react";
import { IStatusLine } from "../../api/status";
import { backgroundClassForStatus } from "../../util/statusBackgroundClasses";

export interface IPickLineStatusRowProps {
  line: IStatusLine;
}

interface IInventoryRecord {
  onHand: number;
  onOrder: number;
  inBin: number;
}

const quantityRecord = (line: IStatusLine): IInventoryRecord => {
  const initialEntry = {onHand: 0, onOrder: line.qty_torder, inBin: 0};

  return line.inv_entry.reduce<IInventoryRecord>((prev, curr) => {
    return {
      onHand: prev.onHand + curr.qty_toh,
      onOrder: prev.onOrder,
      inBin: prev.inBin + ((curr.bin_id.trim() === line.to_bin.trim()) ? curr.qty_toh : 0)
    }
  }, initialEntry)
}

const availableQuantityData = (line: IStatusLine): React.ReactElement => {
  if (line.status === 0 || line.status === 3) {
    // For case 0 or 3 we need the QTY of the given type
    const quantity = line.inv_entry.reduce((prev, curr) => {
      return prev + (curr.type_id === line.type_id ? curr.qty : 0)
    }, 0)

    return (<>{`${quantity} of type ${line.type_id}`}</>);
  } else if (line.status === 1) {
    // For case 1 we need the QTY of all types

    const countsByOtherType = new Map<string, number>();

    line.inv_entry.forEach((invEntry) => {
      const currentValue = countsByOtherType.get(invEntry.type_id) || 0;

      countsByOtherType.set(invEntry.type_id, invEntry.qty + currentValue);
    });

    const keys = Array.from(countsByOtherType.keys()).filter(x => (countsByOtherType.get(x) || 0) > 0);

    return <>
      {keys.map(typeId => {
        const count = countsByOtherType.get(typeId) || 0;
        return (<p key={typeId}>{`${count} of type ${typeId}`}</p>);
      })}
    </>
  } else if (line.status === 2) {
    // For case 2 we need overall quantity
    const overallQty = line.inv_entry.reduce((prev, curr) => prev + curr.qty, 0);
    return (<>{`${overallQty} available overall`}</>);
  }

  return (<></>);
}

const computeWorkOrder = (line: IStatusLine): React.ReactNode => {
  if (line.status > 0) {
    if (!line.prod_no && line.kanban_id) {
      return <>Produce Card: <span className="font-medium">{line.kanban_id}</span></>;
    } else {
      return line.prod_no;
    }
  }

  return "";
}

export const PickLineStatusRow = ({ line }: IPickLineStatusRowProps) => {
  const qtyRecord = React.useMemo(() => quantityRecord(line), [line]);

  const qtyInBinNode = (
    <td>
      {line.to_bin.trim() === "SHIP" && line.wrhs_id.trim() === "099" && "SURPLUS"}
      {line.to_bin.trim() !== 'SHIP' && `${line.qty_in_pick_bin} left in ${line.to_bin}`}
    </td>
  )

  const workOrderSection = computeWorkOrder(line);

  return (
    <tr className={line.ord_priority === 1 ? backgroundClassForStatus(line.status) : ""}>
      <td className={backgroundClassForStatus(line.status)}>
        {line.status}
      </td>
      <td>
        {line.item_id}
      </td>
      <td>
        {line.type_id}
      </td>
      <td>
        {line.to_bin}
      </td>
      <td>
        {qtyRecord.onOrder}
      </td>
      {qtyInBinNode}
      <td>
        {availableQuantityData(line)}
      </td>
      <td>
        {workOrderSection}
      </td>
    </tr>
  )
}
