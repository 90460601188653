import * as React from 'react';
import { PickLineStatusContextProvider } from './context/PickLineStatusContextProvider';
import { AppHeader } from './components/AppHeader';
import { StatusDisplay } from './components/StatusDisplay';
import { ErrorBanner } from './components/ErrorBanner';
import { Loader } from './components/Loader';

function App() {
  return (
    <PickLineStatusContextProvider>
      <ErrorBanner />
      <div className="App p-4 w-full">
          <AppHeader />
          <Loader />
          <StatusDisplay />
      </div>
    </PickLineStatusContextProvider>
  );
}

export default App;
