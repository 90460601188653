import * as React from "react";
import { PickLineStatusContext } from "../context/PickLineStatusContext";

export const ErrorBanner = () => {
  const ctx = React.useContext(PickLineStatusContext);

  return <>
    {ctx.error && (<div className="w-full bg-red-600 text-white font-semibold px-4 py-2">
      <p>{ctx.error}</p>

      <p>Number of failed attempts: {ctx.refreshFailureCount}</p>
    </div>)}
  </>
}
