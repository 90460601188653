import * as React from "react";
import { PickLineStatusContext } from "../context/PickLineStatusContext";


export const AppHeader = () => {
  const ctx = React.useContext(PickLineStatusContext)

  return (
    <>
      <header>
        <h1 className="text-2xl font-medium">Pick Line Material Status</h1>
        
        <p>Updated: {ctx.lastUpdatedAt?.toLocaleTimeString()}</p>
      </header>
    </>
  )
}
