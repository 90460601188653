import { createContext } from "react";
import { IStatusApiResult } from "../api/status";


export interface IPickLineStatusContext {
  status?: IStatusApiResult;
  lastUpdatedAt?: Date;
  refreshFailureCount: number;
  error?: string;
}

const defaultContext: IPickLineStatusContext = {
  refreshFailureCount: 0,
}

export const PickLineStatusContext = createContext<IPickLineStatusContext>(defaultContext);
