import * as React from "react";
import { PickLineStatusContext } from "../context/PickLineStatusContext";
import { StatusSummary } from "./status/StatusSummary";
import { StatusTable } from "./status/StatusTable";


export const StatusDisplay = () => {
  const statusContext = React.useContext(PickLineStatusContext);

  return (
    <div className="w-full p-4">
      {statusContext.status && (<>
        <StatusSummary status={statusContext.status} />
        <StatusTable status={statusContext.status} />
      </>)}
    </div>
  );
}
