import * as React from "react";
import { IStatusApiResult, IStatusLine } from "../../api/status";
import { PickLineStatusRow } from "./PickLineStatusRow";

export interface IStatusTableProps {
  status: IStatusApiResult
}

export const StatusTable = (props: IStatusTableProps) => {
  const createKey = (line: IStatusLine) => `${line.status}--${line.item_id}--${line.type_id}`;

  return (
    <table className="status-table mt-8">
      <thead>
        <tr>
          <th scope="row"></th>
          <th>SKU</th>
          <th>Product Type</th>
          <th>Pick Line</th>
          <th>QTY On Order</th>
          <th>QTY In Bin</th>
          <th>Available QTY</th>
          <th>Work Order</th>
        </tr>
      </thead>
      <tbody>
        {props.status.pickline_status_lines.map(line => (<PickLineStatusRow key={createKey(line)} line={line} />))}
      </tbody>
    </table>
  )
}
