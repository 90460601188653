import * as React from "react";
import { IStatusApiResult, loadPickLineStatus } from "../api/status";
import { IPickLineStatusContext, PickLineStatusContext } from "./PickLineStatusContext";

// In milliseconds
const STATUS_POLLING_INTERVAL = 30000
const RETRY_INTERVAL = 1000
const TIMER_INTERVAL = 500


const useStatusPolling = () => {
  const [status, setStatus] = React.useState<IStatusApiResult>();
  const [error, setError] = React.useState<string>();
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState<Date>(new Date());
  const numFailedRequestsRef = React.useRef(0);
  const [numFailedRequests, setNumFailedRequests] = React.useState<number>(0);

  const lastAttemptRef = React.useRef(0);

  const pollStatus = React.useCallback(async () => {
    const now = Date.now();

    let nextAttemptDiff = STATUS_POLLING_INTERVAL;
    if (numFailedRequestsRef.current > 0) {
      // Set exponential backoff to retry sooner than the status polling interval
      const exponentialDiff = Math.pow(2, numFailedRequestsRef.current - 1) * RETRY_INTERVAL;

      nextAttemptDiff = Math.min(nextAttemptDiff, exponentialDiff);
    }

    if ((now - lastAttemptRef.current) > nextAttemptDiff) {
      lastAttemptRef.current = Date.now();
      const { status, error } = await loadPickLineStatus();
      if (status) {
        setStatus(status);
        setNumFailedRequests(0);
        setError(undefined);
        setLastUpdatedAt(new Date());
      } else {
        setError(error);
        numFailedRequestsRef.current += 1;
        setNumFailedRequests(numFailedRequestsRef.current)
      }
    }
  }, [])

  React.useEffect(() => {
    pollStatus();
    const intvlId = setInterval(pollStatus, TIMER_INTERVAL);

    // Clear the interval on unmount or callback change.
    return () => clearInterval(intvlId);
  }, [pollStatus]);

  return { status, error, numFailedRequests, lastUpdatedAt };
}


export interface IPickLineStatusContextProviderProps {
  children: React.ReactNode;
}


export const PickLineStatusContextProvider = ({children}: IPickLineStatusContextProviderProps) => {
  const { status, error, numFailedRequests, lastUpdatedAt } = useStatusPolling();

  const ctxValue = React.useMemo<IPickLineStatusContext>(() => ({
    status,
    error,
    refreshFailureCount: numFailedRequests,
    lastUpdatedAt,
  }), [status, error, numFailedRequests, lastUpdatedAt])

  return (<PickLineStatusContext.Provider value={ctxValue}>{children}</PickLineStatusContext.Provider>)
}
